@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    li {
        @apply p-4 /* Pading in the li tags of the navbar */
    }

    button {
       @apply text-white border bg-red-700 border-red-700 
       hover:bg-transparent hover:text-red-700 font-bold rounded-md 
    }

}